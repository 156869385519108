.inputStyle {
    padding: 8px;
    margin-bottom: 5px;
    /* outline: none; */
    border: none;
    border-radius: 2.7px;
    width: 75%;
    border-bottom: 1px solid gray !important;
    font-size: 16px !important;
}

#zidex {
    z-index: 10000 !important;
}

.phoneContainer {
    display: flex;
    position: fixed;
    z-index: 1000000 !IMPORTANT;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #0000004a;
    height: 100vh;
}

.phonebox {
    background-color: white;
    width: 50%;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
}

th {
    color: rgb(75, 137, 168);
    text-align: left;
    font-size: 13px;
    font-weight: unset;
}

.BtnS {
    background-color: rgb(0, 173, 81) !important;
    color: white;
    font-weight: 700 !important;
    padding: 7px;
    text-align: center !important;
    /* text-shadow: 0.5px 0.5px black; */
    border: none;
    height: 35px;
    border-radius: 5px;
    cursor: pointer;
}

.phone_c {
    min-height: auto;
    overflow: hidden;
    margin: 20px;
    margin-bottom: 5px;
    padding-right: 0px;
    padding-left: 20px;
}

.closeBTnstyle {
    display: flex;
    justify-content: center;
    padding: 8px 8px;
}


@media (min-width: 335px) and (max-width: 967.98px) {
    .inputStyle {
        width: 90%;
    }

    .closeBTnstyle {
        display: flex;
        justify-content: center;
        padding: 5px 0px;
    }

    .phonebox {
        width: 92%;
    }

    .phone_c {
        margin: 0px 8px;
        margin-bottom: 5px;
        padding-right: 0px;
        padding-left: 0px;
    }
}