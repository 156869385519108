.login_container {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.38);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100 !important;
}

.login_div {
    min-height: 75%;
    max-height: 75%;
    width: 65%;
    background-color: rgb(255, 255, 255);
    border-radius: 25px;
    overflow: hidden;
    display: flex;
}

.left {
    width: 50%;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;

}

.right {
    width: 50%;
    height: inherit;
    position: relative;

}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: small;

}

.right_sec {
    padding-top: 15%;
    padding-top: 54px;
    padding-bottom: 20px;
    margin-left: 5%;
    margin-right: 5%;
}

.row1 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 15px;
}

.rtbn {
    text-align: center;
    letter-spacing: .5px;
    transition: background-color .2s ease-out;
    cursor: pointer;
    height: 43px;
    box-shadow: 0 8px 6px -6px #999;
    line-height: 40px;
    font-weight: 500;
    border-radius: 15px;
    width: 48%;
}

.inputfld {
    position: relative;
    margin: 10px 15px;
    overflow: hidden;
    border: 1px solid #e2e3ec;
    height: 48px;
    border-radius: 12px;
    display: flex;
    align-items: center;
}

.loginInput {
    outline: none;
    border: none;
    height: 33px;
    border-bottom: 1px solid #80808030;
    width: 90%;
    margin-left: 10px;
    font-size: 16px;
    margin-right: 10px ! IMPORTANT;
}

.singhContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.signinbtn {
    padding: 22px 24px;
    height: 40px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    border-radius: 6px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.forgot {
    font-weight: 300;
    margin-top: 20px !important;
}

.countryCode {
    outline: none !important;
    border: none !important;
}

.forgotText {
    color: #666;
    padding: 10px;
    line-height: 25px;
    text-align: center;
    font-size: 17px;
}

img {
    margin: 10px ;
}

.error {
    color: red;
    position: absolute;
    bottom: -3px;
    font-size: 14px;
    left: 62px;
}