.cdp_containter {
    position: fixed;
    height: 100vh;
    z-index: 10000;
    width: 100%;
    background: #0000007a;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cdp_updatebox {
    background-color: white;
    width: 70%;
    min-height: 50%;
    border-radius: 13px;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    justify-content: space-between;

}

.cdp_heading {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    font-weight: 300;
    text-align: center;
    color: white;
    padding: 10px;
}

.cdp_closebtn {
    height: 25px;
    width: 25px;
    background: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: x-large;
}

.nodeClickBtnC {
    background-color: #FF852E !important;
    color: white;
    font-weight: 100 !important;
    padding: 5px;
    text-align: center !important;
    text-shadow: 0.5px 0.5px black;
    border: none;
    height: auto;
    border-radius: 3px;
}

.BtnC {
    background-color: #FF852E !important;
    color: white;
    font-weight: 700 !important;
    padding: 7px;
    text-align: center !important;
    /* text-shadow: 0.5px 0.5px black; */
    border: none;
    height: 35px;
    border-radius: 5px;
    cursor: pointer;
}

.BtnS {
    background-color: rgb(0, 173, 81) !important;
    color: white;
    font-weight: 700 !important;
    padding: 7px;
    text-align: center !important;
    /* text-shadow: 0.5px 0.5px black; */
    border: none;
    height: 35px;
    border-radius: 5px;
    cursor: pointer;
}

.form_area {
    display: flex;
    flex-wrap: wrap;
    margin: 0px 30px;
    position: relative;
    min-height: 33vh;
}

.eachContent {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    margin-bottom: 35px;
    width: 18vw;
}

input {
    padding-left: 0px !important;
    padding: 8px;
    margin-bottom: 5px;
    outline: none;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid gray;
    border-left: none;
    border-image: initial;
    border-radius: 2.7px;
    width: 100%;
}

img {
    margin: 0px;
}

label {
    color: rgb(75, 137, 168);
    font-size: 13px;
    display: flex;
}

#rightCornerPA {
    display: flex;
    height: 50px;
    position: absolute;
    right: -20px;
    top: 0px;
    width: auto;
    z-index: 10000 !important;
}

.addphone {
    cursor: pointer;
    height: 45px;
    margin-right: 4px;
    border-radius: 3px;
}

.star {
    color: red;
    padding-left: 5px;
}

@media (min-width: 335px) and (max-width: 967.98px) {
    .cdp_heading {
        padding: 5px 5px;
    }
    #rightCornerPA {
        right: -10px;
        top: -6px;
    }
    .cdp_updatebox {
        width: 95%;
        min-height: auto;
    }

    .form_area {
        min-height: 21vh;
        justify-content: center;
    }

    .eachContent {
        width: 55vw;
    }

    .BtnC {
        height: 25px !important;
        font-weight: 100 !important;
    }

    .BtnS {
        height: 25px !important;
        font-weight: 100 !important;
    }
}