@font-face {
  font-family: "heading";
  /* src: url('./heading.ttf') format(' truetype'); */
  src: url("./normalText.ttf") format("truetype");
}

@font-face {
  font-family: "textN";
  src: url("./normalText.ttf") format("truetype");
}

* {
  /* font-family: heading !important; */
  font-family: "Lato", sans-serif;
}

.bolderr {
  font-family: heading !important;
  font-size: xx-large;
  font-weight: bold;
}

.nodeClickBtnC {
  background-color: #ff852e !important;
  color: white;
  font-weight: 900 !important;
  padding: 7px;
  text-align: center !important;
  text-shadow: 0.5px 0.5px black;
  border: none;
  height: 35px;
  border-radius: 5px;
}

.nodeClickBtnC:hover {
  background-color: white !important;
  border: 1px solid gray;
  color: rgb(82, 82, 82);
  box-shadow: 0px 0px 5px 0px rgb(255, 255, 255);
  text-shadow: 0.5px 0.5px rgb(200, 200, 200);
}

/* src/RecommendationList.css */

.recommendation-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  padding: 15px 150px;
}

.recommendation-item {
  padding: 2px;
  width: 20vw;
}

#rH1 {
  font-family: heading !important;
  font-size: 20px !important;
  margin-bottom: 10px;
  font-weight: bolder !important;
  line-break: auto;
  color: black;
}

#rP {
  font-family: textN !important;
  font-size: 15px;
  line-height: 1.5;
  line-break: auto;
  color: #333;
  margin-bottom: 10px !important;
}

.alt-text p span {
  font-family: textN !important;
  font-size: 15px !important;
  line-height: 1.5;
  line-break: auto;
  color: #333;
  margin-bottom: 10px !important;
}

.alt-text p strong span {
  font-family: heading !important;
  font-size: 20px !important;
  margin-bottom: 10px;
}

.alt-text h2 strong span {
  font-family: heading !important;
  font-size: 20px !important;
  margin-bottom: 10px;
}

#viewMore {
  cursor: pointer;
  font-size: 15px;
  font-weight: bold !important;
}

@media (max-width: 768px) {
  .recommendation-container {
    grid-template-columns: 1fr;
  }
}

/* use cases access  Section type id 43 */

.slider-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* overflow-x: hidden; */
  /* width: 100%; */
  /* height: 415px; */
  margin: 10px 130px;
  margin-bottom: 10px;
}

.caroussel-dots>* {
  background-color: #000;
  border-radius: 7px;
  cursor: pointer;
  height: 15px;
  margin: 0 5px;
  width: 15px;
  border-radius: 50%;
}

.image-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  height: 100%;
}

.slider {
  display: flex;
  flex-direction: row;
  transition: transform 0.5s ease-in-out;
}

.slide {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  overflow: hidden;
  padding-bottom: 40px;
}

.slide img {
  width: auto;
  height: 100%;
}

.button_Cntr {
  display: flex !important;
  position: absolute;
  width: 100%;
  bottom: -4%;
  justify-content: center;
  transform: translateY(-50%);
  padding: 0px 30px;
}

.fc-popCloseTag {
  position: relative;
  background-color: white;
  color: red;
  height: 25px;
  width: 25px;
  top: 10px;
  right: 10px;
  cursor: pointer;

}

.btn_rds {
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 5px;
}

.button_Cntr img {
  height: 16px;
  margin: 0px !important;
  cursor: pointer;
}

.nav-btn {
  width: auto;
  height: auto;
  background-color: #33333300;
  color: rgb(0, 0, 0);
  border: none;
  cursor: pointer;
  font-size: 50px;
  margin-bottom: 4px;
}

.nav-btn:hover {
  background-color: #5555554d;
  border-radius: 50%;
}

/* Navigations styles */

.NavigationSelect {
  outline: none;
  background-color: white;
  font-size: 16px;
  color: #595959;
  border: none !important;
  cursor: pointer;
  width: 135px;
}

.NavigationOption {
  cursor: pointer;
  padding: 115px !important;
  margin: 10px !important;
  /* background-color: lightblue !important; */
  border: none !important;
  outline: none !important;
}

/* footertext style */

.footer_text {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 7vh;
  color: rgb(255, 255, 255);
}

.footer_text span {
  font-size: 11px !important;
  font-weight: bolder;
}

.footer_content {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin: 30px 150px;
  min-height: 80px;
}

.footer_content span {
  font-size: 15px !important;
  cursor: pointer;
}

.footer_img {
  height: 30px;
  cursor: pointer;
  margin: 10px 5px;
}

.section_name {
  font-family: heading !important;
  position: relative;
  padding: 8px;
  top: 8px;
  font-size: x-large;
  text-align: center;
  font-weight: bold;
}

/* Responsive styles */

.s-25 {
  padding-bottom: 5px;
  position: relative;
}

.cc-25 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  flex-wrap: nowrap;
  padding-top: 9px;
  gap: 20px;
}

.img25 {
  height: 5vw;
  width: auto !important;
  /* height: 65px; */
  /* min-height: 65px; */
  /* width: fit-content; */
}

.useCase {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 125px;
}

.useCaseimg {
  flex: 1;
  height: 350px;
  width: auto;
  display: flex;
  justify-content: center;
}

.alt-text {
  margin-top: 10px;
  color: #333;
  flex: 20%;
}

/* 2 ->here we are updating the slider section width as per the view width from 90vh*/
.full_screen {
  height: 43vw;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.0025) 0.1px 0.9px 5px;
  align-items: center;
  justify-content: start;
  overflow: hidden;
  /* margin: 0px 2.7px; */
  background-size: cover;
}

.fullscreen_cb {
  font-size: 13px;
}

.btnSize {
  margin-top: 30px;
  width: auto;
  cursor: pointer;
  font-weight: 600 !important;
  text-shadow: none !important;
}

.slider_left_container {
  margin-left: 150px;
  display: flex;
  align-items: start;
  justify-content: start;
  width: 100%;
  flex-flow: column;
}

.slider_left_container p {
  font-size: 34px !important;
}

/* 1 */
.NavigationC {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  box-shadow: rgb(100, 100, 100) 0px 0.5px 4px 0px;
  padding: 4px 110px;
  height: 12vh;
  position: fixed;
  top: 0px;
  z-index: 1;
  width: 100%;
  background: white;
}

.v_slider {
  height: 400px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin: 5px 125px;
}

.sectionExceptNavigation {
  min-height: 88vh;
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;
  margin-top: 12vh;
  display: flex;
  flex-flow: column;
  /* justify-content: space-between; */
  justify-content: end;
}

.navigation_link {
  display: flex;
}

.mobile-menu {
  display: none;
}

.nav-options {
  display: none !important;
  padding-left: 25px;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 50px;
  list-style-type: none;
}

.extra {
  display: none !important;
}

.option {
  padding: 20px 50px;
  margin: 5px 25px;
  border-radius: 4px;
}

.header_logo {
  height: 55px;
  cursor: pointer;
}

.navigationBTN {
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0em;
  line-height: 1.4em;
  font-size: 16px !important;
  color: #000000;
  padding: 0px 10px;
  font-size: 13px;
  margin-right: 5px;
  color: #595959;
  cursor: pointer;
}

.loginBTN {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 7px 20px;
  cursor: pointer;
  margin-left: 10px;
}

.contactusinput {
  padding: 8px 8px 8px 0px;
  margin-bottom: 5px;
  outline: none;
  border-top: none;
  border-right: none;
  border-bottom: 1px solid gray;
  border-left: none;
  border-image: initial;
  border-radius: 2.7px;
  width: 51%;
}

.s-27c {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: end;
  overflow: hidden;
  background-size: cover;
}

.s-27cc {
  height: inherit;
  display: flex;
  flex-flow: column;
  width: 50%;
  justify-content: center;
}

.s-27form {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-flow: column;
}

.viewmoreContainer {
  height: 85%;
  width: 55%;
  background: white;
  border-radius: 10px;
  position: relative;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px 10px;
  flex-flow: column;
  align-items: center;
}

.textviewmore {
  font-size: 10px;
  overflow: auto;
  width: 100%;
  padding: 0px;
  word-break: break-word;
  overflow-wrap: break-word;
}

.viewmoreM img {
  height: 30%;
  border-radius: 5px;
  width: fit-content;
}

.viewmoreBTN {
  border: none;
  color: white;
  position: absolute;
  right: 3px;
  top: 3px;
  border-radius: 14px;
  height: 25px;
  width: 25px;
  font-size: small !important;
  font-size: 9px;
  z-index: 10;
  cursor: pointer;
}

.viewMoreSectionTitle {
  text-overflow: ellipsis !important;
  /* font-size: 22px !important; */
  padding: 3px 0px;
  overflow: hidden;
  font-weight: bolder !important;
  white-space: nowrap;
  width: 27ch;
}

/* h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1 !important;
} */

/* here overriding the default css of the HTML content */
/* .alt-texts h1 span {
  font-size: 22pt;
}

.alt-texts h2 span {
  font-size: 20pt;
}

.alt-texts h3 strong span {
  font-size: 18pt;
}

.alt-texts h4 span {
  font-size: 14pt;
}
.alt-texts h1  {
  font-size: 22pt;
}

.alt-texts h2  {
  font-size: 20pt;
}

.alt-texts h3   {
  font-size: 18pt;
}

.alt-texts h4  {
  font-size: 14pt;
} */
.htmlContent {
  padding: 10px 150px;
}

.blog_container {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: start;
  /* min-height: 60vh; */
  height: auto;
  flex-flow: column;
  padding: 16px 0px 0px 0px !important;
}

.blog_c_container {
  padding: 10px 150px;
  color: black;
  width: 100%;
}

.blog_content {
  display: flex;
  height: 25vh;
  box-shadow: black 0px 0px 6px 0px;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
}

.blog_c_left {
  width: 40%;
}

.blog_c_left img {
  width: 100%;
  height: 100%;
  border-radius: 5px !important;
}

.blog_c_right {
  position: relative;
  padding: 10px;
  width: 63%;
}

.blog_c_right div {
  font-size: 15px;
}

.blog_c_right p {
  font-size: 13px;
}

.read_more {
  position: absolute;
  right: 10px;
  bottom: 10px;
  border: none;
  color: white;
  width: unset;
  padding: 5px 7px;
  border-radius: 4px;
  cursor: pointer;
}

.read_moreC {
  position: fixed;
  right: 30px !important;
  top: 50px !important;
  border: none;
  color: white;
  width: unset;
  padding: 5px 7px;
  border-radius: 4px;
  cursor: pointer;
}

.mainBlog {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  padding: 0px 0px 30px;
}

.mainBlog img {
  height: 40vh;
  border-radius: 5px;
}

.mainBlog p {
  font-size: 23px;
}

.margin5 {
  margin: 5px 0px;
  flex-basis: 33%;
}

.padding5 {
  padding: 0px 5px;
}

.footerlogo {
  height: 42px;
  position: relative;
  bottom: 15px;
}

.sc-26 {
  padding: 10px 80px;
}

.scContent-26 {
  display: flex;
  min-height: "30vh";
  max-height: "30vh";
  padding: "10px 80px";
  margin: "10px";
  overflow: "hidden";
}


.scContent-6 {
  margin: 5% 0% !important;
}


.scFont-6 {
  font-size: 18px;
  margin: 4px;
  font-weight: 300;

}

.sc-6 {
  padding: 10px 80px;
}

.scparafont-6 {
  font-size: 13px;
  text-align: center;
  color: black;
  height: 45px;
  line-break: auto;
}

.scTotal-6 {

  /* height: 50vh;  */
  /* max-height: 65vh; */
  width: 47vh;
  border-radius: 6px;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.0025) 0.1px 0.9px 5px;
  align-items: center;
  justify-content: space-around;
  overflow: hidden;
  margin: 0px 2.7px;
  background-size: cover;
}

/* .sContent-6{
  display: flex !important; 
  justify-content: center !important ; 
  align-items: center !important; 
  height: auto !important;
} */

.se-5 {
  position: relative !important;
  padding: 10px 80px !important;
}

.scontent-5 {
  height: 45vh !important;
  border-radius: 6px !important;
  width: 40vw !important;
  display: flex !important;
  box-shadow: rgba(0, 0, 0, 0.0025) 0.1px 0.9px 5px !important;
  align-items: center !important;
  justify-content: space-around !important;
  overflow: hidden !important;
  margin: 0px 2.7px !important;
  background-size: cover !important;
}

.marginL5 {
  margin-left: 5px;
}

.marginR50 {
  margin-right: 50px;
}

#bg_contain {
  background-size: contain !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  border-radius: 8% !important;
  background-size: 100% 100% !important;
  height: 250px !important;
  width: 250px !important;
  position: relative !important;
  cursor: pointer !important;
  box-shadow: none !important;
}




/* 375 767 */
@media (min-width: 335px) and (max-width: 967.98px) {

  #modelImage {
    width: 100% !important;
    height: auto !important;
    max-height: 50vh !important;
    object-fit: contain !important;
    margin-bottom: 1px !important;
    border-radius: 4px !important;
    text-align: center !important;
  }

  .modelContainer55 {
    height: auto !important;
    width: 100% !important;
  }

  /* .fc-popCloseTag {
    top: 40px !important;
    right: 40px !important;
  } */

  .font55 {
    padding: 20% 0% !important;
  }

  .container55 {
    width: 80% !important;
  }

  #closesec {
    margin-bottom: 60px !important;
  }

  .marginL5 {
    margin-left: 0px;
  }

  .marginR50 {
    margin-right: 0px;
  }

  .scontent-6 {
    flex-flow: column;
  }

  .contentText-26 {
    margin-right: 0px !important;

  }

  .sc-26 {
    text-align: justify !important;
    text-align: center !important;
    flex-direction: column !important;
    padding: 10px 20px;
    border-radius: 5px !important;
  }

  .scContent-26 {
    flex-direction: column-reverse !important;
    padding: 20px !important;
    justify-content: center !important;
    border-radius: 5px !important;


  }

  .scContent-26 img {
    height: 150px !important;
    width: 300px !important;
    object-fit: cover !important;
    overflow: hidden !important;
    border-radius: 5px !important;
  }

  .sc-6 {
    padding: 10px 20px !important;
  }

  .scFont-6 {
    font-Size: 16px !important;
    margin: 4px !important;
    font-weight: 300 !important;
  }

  .scTotal-6 {

    height: auto !important;
    /* max-height: 65vh; */
    width: 47vh !important;
    border-radius: 6px !important;
    display: flex !important;
    box-shadow: rgba(0, 0, 0, 0.0025) 0.1px 0.9px 5px;
    align-items: center !important;
    justify-content: space-around !important;
    overflow: hidden !important;
    margin: 0px 2.7px !important;
    background-size: cover !important;
    margin-right: '50px' !important;
  }

  .scontent-6 {
    /* display: flex !important; 
    justify-content: center !important ;  */
    align-items: center !important;
    /* height: auto !important;
    margin-right: '50px' !important; */
  }

  .scfontpara-6 {
    font-size: 13px !important;
    text-align: left !important;
    color: black !important;

    line-break: auto !important;
    padding: 20px !important;
    padding-top: 5px !important;

  }

  .se-5 {
    position: relative !important;
    padding: 10px 20px !important;
  }

  .scontent-5 {
    height: 45vh !important;
    border-radius: 6px !important;
    width: 95% !important;
    display: flex !important;
    box-shadow: rgba(0, 0, 0, 0.0025) 0.1px 0.9px 5px !important;
    align-items: center !important;
    justify-content: space-around !important;
    overflow: hidden !important;
    margin: 0px 2.7px !important;
    background-size: cover !important;
  }

  .Largeimage38 {
    min-height: 22vh !important;
  }

  .Largeimageimage {
    height: auto !important;
    width: 100% !important;
  }

  .padding5 {
    padding: 0px 0px;
  }

  .sliding-text {
    display: flex;
    animation: slide 20s linear infinite;
    animation-play-state: running;
  }

  .cc-25 {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    height: auto;
    justify-content: center;
    padding-top: 9px;
  }

  .img25 {
    width: 100px !important;
    height: 100px !important;
    /* //object-fit: contain; */
  }

  @keyframes slide {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(-50%);
    }
  }

  #bg_contain {
    height: 190px !important;
    width: 190px !important;
  }

  .footerlogo {
    height: 35px;
    position: relative;
    bottom: 3px;
  }

  .margin5 {
    margin: 1px 0px;
  }

  .mainBlog p {
    font-size: 16px;
  }

  .mainBlog {
    padding: 0px 0px 9px;
  }

  .mainBlog img {
    height: 15vh;
  }

  .read_more {
    bottom: 10px;
  }

  .blog_c_right {
    width: 100%;
    height: 100%;
  }

  .blog_content {
    /* flex-flow: column; */
    align-items: center;
    min-height: 15vh;
    max-height: 20vh;
    padding: 5px;
  }

  .blog_c_container {
    padding: 10px 25px;
  }

  .htmlContent {
    padding: 10px 30px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1 !important;
  }

  .alt-texts h1 span {
    font-size: 14pt !important;
  }

  .alt-texts h2 span {
    font-size: 12pt !important;
  }

  .alt-texts h3 strong span {
    font-size: 10pt !important;
  }

  .alt-texts h3 span {
    font-size: 10pt !important;
  }

  .alt-texts h4 span {
    font-size: 9pt !important;
  }

  .alt-texts h1 {
    font-size: 14pt !important;
  }

  .alt-texts h2 {
    font-size: 12pt !important;
  }

  .alt-texts h3 {
    font-size: 10pt !important;
  }

  .alt-texts h4 {
    font-size: 9pt !important;
  }

  .alt-texts p span {
    font-size: 10pt !important;
  }

  .viewmoreM img {
    height: 90px;
  }

  .viewmoreContainer {
    height: 52%;
    width: 91%;
  }

  .contactusinput {
    width: 70%;
  }

  .s-27form {
    align-items: center;
  }

  .s-27cc {
    width: 100%;
    padding: 20px;
  }

  .about_us {
    padding: 10px 23px !important;
  }

  .slider_left_container p {
    font-size: 12px !important;
  }

  .slide {
    align-items: center;
    padding-bottom: 0px !important;
    border-radius: 5px !important;
  }

  .button_Cntr {
    bottom: -1.5%;
  }

  .btn_rds {
    height: 30px;
    width: 30px;
  }

  .login_div {
    min-height: auto;
    max-height: 95%;
    width: 95%;
  }

  .login_div .left {
    display: none !important;
  }

  .login_div .right {
    width: 100%;
  }

  .button_Cntr {
    padding: 0px !important;
  }

  .slider-container {
    margin: 0px 20px 44px !important;
  }

  .slide {
    flex-direction: column;
  }

  .header_logo {
    height: 42px;
  }

  .extra {
    display: inline-block !important;
  }

  .nav-options {
    display: flex !important;
    width: 100%;
    height: auto;
    position: absolute;
    top: 60px;
    left: -100%;
    opacity: 0;
    justify-content: start;
    transition: all 0.5s ease;
    flex-direction: column;
    list-style-type: none;
    grid-gap: 0px;
  }

  .nav-options.active {
    justify-content: start;
    background: rgb(255, 255, 255);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    align-content: center;
    padding-left: 0px;
    /* padding: 7px 0px; */
  }

  .mobile-menu {
    display: block;
  }

  .navigation_link {
    display: none;
  }

  .v_slider {
    display: block;
    margin: 0px 20px;
  }

  .s-25 {
    padding: 3px 0px;
  }

  .img25 {
    margin: 0px !important;
    height: 40px !important;
    min-height: 25px !important;
    /* width: 70px !important; */
    border-radius: 20% !important;
  }

  .sectionExceptNavigation {
    margin-bottom: 61% !important;
  }

  .section_name {
    font-size: small;
    padding-top: 0px !important;
    padding: 3px;
    top: 10px;
    margin-bottom: 10px !important;
  }

  /* 42 */
  .recommendation-container {
    padding: 5px 20px;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }

  .recommendation-item {
    width: 100% !important;
  }

  /* 43 */
  .useCase {
    height: auto !important;
    display: flex;
    align-items: center;
    flex-flow: row;
    justify-content: space-between;
    margin: 5px 125px;
  }

  .useCaseimg {
    flex: 1;
    height: 50px;
    text-align: center;
    width: auto;
  }

  .alt-text {
    margin-top: 10px;
    color: #333;
    flex: 20%;
  }

  .alt-text p span {
    /* font-size: 8.5px !important; */
  }

  .alt-text p {
    font-size: 14px !important;
  }

  .alt-text h2 strong span {
    font-size: 17px !important;
  }

  .alt-text p strong span {
    font-size: 17px !important;
  }

  /* here we are fixing the image height of the usecase 43*/
  .useCaseimg img {
    margin: 0px !important;
    height: 160px !important;
  }

  /* 7 */
  .footer_content {
    display: flex;
    justify-content: space-around;
    align-items: start;
    margin: 10px 3px;
    min-height: 70px;
  }

  .footer_text span {
    font-size: 8px !important;
    font-weight: 100;
  }

  .footer_content span {
    font-size: 7.9px !important;
    cursor: pointer;
  }

  .footer_text {
    min-height: 3vh;
  }

  .footer_img {
    height: 20px;
    cursor: pointer;
    margin: 6px 4px !important;
  }

  .bolderr {
    font-weight: bold;
    font-size: small;
  }

  /* 2 */
  .full_screen {
    height: 20vh;
  }

  .fullscreen_cb {
    font-size: 8px;
  }

  .slider_left_container {
    margin-left: 29px;
    display: flex;
    /* align-items: center; */
    justify-content: start;
    /* width: 37%; */
    flex-flow: column;
  }

  .btnSize {
    font-size: xx-small;
    margin-top: 8px;
    width: auto;
  }

  .nodeClickBtnC {
    background-color: #ff852e !important;
    color: white;
    font-weight: 100 !important;
    padding: 5px;
    text-align: center !important;
    text-shadow: 0.5px 0.5px black;
    border: none;
    height: auto;
    border-radius: 3px;
  }

  /* 1 */
  .NavigationC {
    height: 60px;
    padding: 7px;
    /* box-shadow: none;
    border-bottom: 0.0001px solid black; */
  }

  .sectionExceptNavigation {
    margin-top: 60px;
  }

  .cc-25 {
    margin: 0px 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    flex-wrap: nowrap;
    padding: 0px !important;
    gap: 13px;
  }
}

.sideoptions_container {
  display: flex;
  justify-content: start;
  flex-flow: column;
  background-color: white;
  padding: 7px 0px;
}

.menu-icon {
  height: 18px;
  max-width: 26px;
  width: 26px;
  /* height: 20px;
  max-width: 37px; */
}

.sliding-text {
  display: flex;
  padding-left: 100%;
  animation: slide 20s linear infinite;
}

@keyframes slide {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-100%);
  }
}

.redBottomBorder {
  border-bottom: 1px solid red !important;
}

.passwordvalidation {
  margin: 10px 50px;
  font-size: 11px;
}

.viewmoreM {
  position: fixed;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: end;
  height: 100%;
  width: 100%;
  background: #00000082;
  z-index: 1000000000 !important;
}