.addressContainer {
    display: flex;
    position: fixed;
    z-index: 1000000 !IMPORTANT;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #0000004a;
    height: 100vh;
}

.addressbox {
    background-color: white;
    width: 70%;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
}

.form_area {
    display: flex;
    flex-wrap: wrap;
    margin: 0px 30px;
    position: relative;
}

.eachContentAddress {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    margin-bottom: 21px;
    width: 18vw;
}

.closebtnaddress {
    display: flex;
    justify-content: space-between;
    padding: 8px 8px;
    margin-top: 0px;
}


@media (min-width: 335px) and (max-width: 967.98px) {
    .closebtnaddress {
        padding: 5px;
    }

    .addressbox {
        width: 92%;
    }

    .form_area {
        margin: 0px 12px;
    }

    .eachContentAddress {
        margin-right: 8px;
        margin-bottom: 21px;
        width: 40vw;
    }
}